import React, { useEffect } from 'react';
import { useUser } from "../../components/UserContext";
import EnquiryCustomer from '../Customer/EnquiryCustomer'
import useFullPageLoader from "../../components/useFullPageLoader";
import axios from "../../components/axios";
import { Link } from 'react-router-dom';


const HomePage = () => {
  const { userData } = useUser();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [dataa, setDataa] = React.useState({

  });


  const fetchData = async () => {
    showLoader();
    try {
      const count = await axios.get(`/getEnquiryCount`);
      const res = await axios.get(`/getCountOfCertificateIssued`);
      const response = await axios.get(`/getActiveCount`);
      const result = await axios.get(`/getPendingCount`);
      setDataa({
        EnquiryCount: count.data,
        CertificateIssued: res.data,
        ActiveCount: response.data,
        PendingCount: result.data
      })
      hideLoader();
    } catch (error) {
      console.error("Error fetching data:", error);
      hideLoader();
    }
    
  };

  useEffect(() => {
    fetchData();
  }, [userData]);

  return (
    <div>
      {userData?.Role && (
        <div className="row">
          <div className="col-sm-6 col-lg-3 mb-4">
            <div className="card card-border-shadow-primary">
              <div className="card-body">
              <Link
                    to="/viewEnquiry"
                    style={{ textDecoration: "none", color: "#757575" }}
                  >
                <div className="d-flex align-items-center mb-2 pb-1">
                  <div className="avatar me-2">
                    <span className="avatar-initial rounded bg-label-primary"><i className="ti ti-messages ti-md"></i></span>
                  </div>
                  <h4 className="ms-1 mb-0">{dataa.EnquiryCount}</h4>
                </div>
               
                <p className="mb-1">No of Enquiries</p>
                <p className="mb-0">
                  <span className="fw-medium me-1"></span>
                  <small className="text-muted"></small>
                </p>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3 mb-4">
            <div className="card card-border-shadow-success">
              <div className="card-body">
              <Link
                    to="/viewEnquiry"
                    style={{ textDecoration: "none", color: "#757575" }}
                  >
                <div className="d-flex align-items-center mb-2 pb-1">
                  <div className="avatar me-2">
                    <span className="avatar-initial rounded bg-label-success"><i className="ti ti-cash-banknote ti-md"></i></span>
                  </div>
                  <h4 className="ms-1 mb-0">{dataa.ActiveCount}</h4>
                </div>
                <p className="mb-1">Active Enquiries</p>
                <p className="mb-0">
                  <span className="fw-medium me-1"></span>
                </p> </Link>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3 mb-4">
            <div className="card card-border-shadow-warning">
              <div className="card-body">
              <Link
                    to="/viewEnquiry"
                    style={{ textDecoration: "none", color: "#757575" }}
                  >
                <div className="d-flex align-items-center mb-2 pb-1">
                  <div className="avatar me-2">
                    <span className="avatar-initial rounded bg-label-warning">
                      <i className="ti ti-exclamation-circle ti-md"></i></span>
                  </div>
                  <h4 className="ms-1 mb-0">{dataa.PendingCount}</h4>
                </div>
                <p className="mb-1">Pending Enquiries</p>
                <p className="mb-0">
                  <span className="fw-medium me-1"></span>
                </p> </Link>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3 mb-4">
            <div className="card card-border-shadow-info">
              <div className="card-body"><Link
                    to="/viewEnquiry"
                    style={{ textDecoration: "none", color: "#757575" }}
                  >
                <div className="d-flex align-items-center mb-2 pb-1">
                  <div className="avatar me-2">
                    <span className="avatar-initial rounded bg-label-info"><i className="ti ti-file-invoice ti-md"></i></span>
                  </div>
                  <h4 className="ms-1 mb-0">{dataa.CertificateIssued}</h4>
                </div>
                <p className="mb-1">Certificate Issued</p>
                <p className="mb-0">
                  <span className="fw-medium me-1"></span>
                  <small className="text-muted"></small>
                </p> </Link>
              </div>
            </div>
          </div>
        </div>
      )}

      {!userData?.Role && (
        <EnquiryCustomer />
      )}
      {loader}
    </div>
  );
};

export default HomePage;

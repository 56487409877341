export function formatDate(dateString) {
    if (!dateString) return ''; 

    try {
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        const formattedDate = new Date(dateString).toLocaleDateString("en-GB", options);
        return formattedDate !== "Invalid Date" ? formattedDate : "Invalid Date";
    } catch (error) {
        console.error("Error formatting date:", error);
        return "Invalid Date";
    }
}


export function formatTime(timeString) {
    try {
      if (!timeString) return '';
  
      const [time, meridiem] = timeString.split(' ');
  
      let [hours, minutes] = time.split(':');
      if (meridiem === 'PM') {
        hours = (parseInt(hours, 10) + 12).toString(); // Convert to 24-hour format
      } else if (hours === '12' && meridiem === 'AM') {
        hours = '00'; // Handle midnight
      }
  
      return `${hours}:${minutes}`;
    } catch (error) {
      console.error("Error formatting time:", error);
      return "Invalid Time";
    }
  }
  
  export  function formatInputDate(dateString) {
    if (!dateString) {
      return "";
    }

    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }
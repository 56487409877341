import React, { useState, useEffect } from "react";
import useFullPageLoader from "../../components/useFullPageLoader";
import axios from "../../components/axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Select from "react-select"

function randomString(length, chars) {
  var mask = "";
  if (chars.indexOf("a") > -1) mask += "abcdefghijklmnopqrstuvwxyz";
  if (chars.indexOf("A") > -1) mask += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  if (chars.indexOf("#") > -1) mask += "0123456789";
  if (chars.indexOf("!") > -1) mask += "~`!@#$%^&*()_+-={}[]:\";'<>?,./|\\";
  var result = "";
  for (var i = length; i > 0; --i)
    result += mask[Math.floor(Math.random() * mask.length)];
  return result;
}

const CustomerEnquiry = () => {
  const navigate = useNavigate();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [errors, setErrors] = useState({});
  const [certificateOption, setCertificateType] = useState([]);
  const [invoiceNumber, setInvoiceNumber] = useState("");
  //console.log(userData?.Org_Id)
  useEffect(() => {
    (async () => {
      showLoader();
      try {
        const certificateType = await axios.get(`/getCertificateType`);
        const Option = certificateType.data.map((option) => ({
          label: option.Certificate_Name,
          value: option.Sr_No,
        }));
        setCertificateType(Option);
        const result = await axios.get(`/getCustomerId`);
        const nextInvoiceNumber = generateNextInvoiceNumber(result.data);
        setInvoiceNumber(nextInvoiceNumber);
        setCustomer({
          ...customer,
          CustomerId: nextInvoiceNumber,
        })
      } catch (error) {
        console.error("Error fetching invoices:", error);
      }

      hideLoader();
    })();
  }, []);


  function generateNextInvoiceNumber(existingInvoices) {
    if (existingInvoices.length === 0) {
      return "EPR-0001";
    }
    const lastInvoiceNumber = existingInvoices;
    const lastNumber = parseInt(lastInvoiceNumber.split("-")[1]);
    const nextNumber = lastNumber + 1;

    // Format the next invoice number with leading zeros
    const nextInvoiceNumber = `EPR-${String(nextNumber).padStart(4, "0")}`;

    return nextInvoiceNumber;
  }

  const [customer, setCustomer] = useState({
    CustomerId: "",
    First_Name: "",
    Last_Name: "",
    Company_Name: "",
    Reference: "",
    Remarks: "",
    DOB: "",
    Certificate_Id: "",
    Certificate_Name: "",
    Gender: "",
    Mobile: "",
    Email: "",
    Address: "",
    Status: "Pending",
  });

  const handleSubmit = (event) => {
    event.preventDefault();

    const Updatedcustomer = {
      ...customer,
      CustomerId: invoiceNumber,
      Username: customer?.Mobile,
      Password: randomString(8, '#Aa')
    };

    const validationErrors = {};

    if (!/^[a-zA-Z\s]+$/.test(customer.First_Name)) {
      validationErrors.First_Name = "Invalid Input";
    }
    if (!/^[a-zA-Z\s]+$/.test(customer.Last_Name)) {
      validationErrors.Last_Name = "Invalid Input";
    }
    if (!/^([6-9][0-9]{9,11})$/.test(customer.Mobile)) {
      validationErrors.Mobile = "Invalid Mobile Number";
    }

    if (
      !/^[\w.-]+@[a-zA-Z\d-]+(\.[a-zA-Z\d-]+)+$/.test(customer.Email) &&
      customer.Email !== ""
    ) {
      validationErrors.Email = "Invalid Email Address";
    }

    if (Object.keys(validationErrors).length === 0) {
      showLoader();
      axios
        .post(`/addCustomer`, Updatedcustomer)
        .then((res) => {
          Swal.fire("Success!", "Your Response Has Been Submitted Successfully,<br>Kindly Check Your Whatsapp for login credentials.", "success").then(
            (result) => {
              if (result.isConfirmed) {
                window.location.reload();
              }
            }
          );
          hideLoader();
        })
        .catch((err) => {
          // error("Server is Busy!!");
          hideLoader();
        });
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <div class="content" style={{ padding: "3rem 0", backgroundColor: "#262477" }}>

      <div class="container">
        <div class="row">
          <div class="col-md-4 " style={{ marginRight: "auto" }}>
            <div style={{ textAlign: "center" }}>
              <img src="/assets/img/logo.png" style={{ width: "150px" }} ></img>
            </div>

            <p style={{ marginTop: "25px" }}>
  <ul>
    <li>Fill out all the fields in the form accurately.</li>
    <li>Double-check your information before submission to ensure correctness.</li>
    <li>After submitting the form, you will receive login credentials via whatsapp.</li>
    <li>Use the provided link in the whatsapp to upload the payment attachment and required documents.</li>
    <li>Ensure all documents are uploaded in the specified format and size.</li>
  </ul>
</p>

            <p>For any assistance or inquiries, please contact</p>
            <b>Contact Number : </b><span>9322811574</span> <br />
            <b>Email Address : </b><span>santoshsonavane@gmail.com</span><br />
            <b>Address : </b><span>Office no 128, Devi Annapurna Society,plot no 08, sector 18,Vashi Navi Mumbai 400705</span>

          </div>

          <div class="col-md-7">
            <div class="box">
              <h3 class="heading" style={{ fontSize: "20px", marginBottom: "15px" }}>How Can We Help?</h3>
              <form method="post" id="contactForm" name="contactForm" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-3 d-none">
                    <div className="mb-2">
                      <label htmlFor="CustomerId " class="col-form-label">
                        Customer Id
                      </label>
                      <input
                        type="text"
                        id="CustomerId"
                        className="form-control"
                        placeholder="Enter Customer Id"
                        disabled
                        value={invoiceNumber}
                      // required
                      // onChange={(e) =>
                      //   setCustomer({
                      //     ...customer,
                      //     CustomerId: e.target.value,
                      //   })
                      // }
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-2">
                      <label htmlFor="txtFname" className="col-form-label">
                        First Name<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        id="txtFname"
                        className="form-control"
                        placeholder="Enter First Name"
                        required
                        onChange={(e) =>
                          setCustomer({
                            ...customer,
                            First_Name: e.target.value,
                          })
                        }
                      />
                      <p className="text-danger">{errors.First_Name}</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-2">
                      <label htmlFor="txtLname" className="col-form-label">
                        Last Name<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        id="txtLname"
                        className="form-control"
                        placeholder="Enter Last Name"
                        required
                        onChange={(e) =>
                          setCustomer({
                            ...customer,
                            Last_Name: e.target.value,
                          })
                        }
                      />
                      <p className="text-danger">{errors.Last_Name}</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-2">
                      <label htmlFor="Company_Name" className="col-form-label">
                        Company Name
                      </label>
                      <input
                        type="text"
                        id="Company_Name"
                        className="form-control"
                        placeholder="Enter Company Name"
                        onChange={(e) =>
                          setCustomer({
                            ...customer,
                            Company_Name: e.target.value,
                          })
                        }
                      />
                      <p className="text-danger">{errors.Company_Name}</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-2">
                      <label htmlFor="txtMobile" className="col-form-label">
                        Mobile<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        maxLength={10}
                        value={customer.Mobile}
                        id="txtMobile"
                        className="form-control"
                        placeholder="Enter Mobile Number"
                        required
                        onChange={(e) => {
                          const input = e.target.value;
                          const numericInput = input.replace(/[^0-9]/g, "");
                          setCustomer({ ...customer, Mobile: numericInput });
                        }}
                      />
                      <p className="text-danger">{errors.Mobile}</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-2">
                      <label htmlFor="txtEmail" className="col-form-label">
                        Email
                      </label>
                      <input
                        type="text"
                        id="txtEmail"
                        className="form-control"
                        placeholder="Enter Email Id"
                        onChange={(e) =>
                          setCustomer({ ...customer, Email: e.target.value })
                        }
                      />
                      <p className="text-danger">{errors.Email}</p>
                    </div>
                  </div>
                  <div className="col-md-6 d-none">
                    <div className="mb-2">
                      <label htmlFor="dropGender" className="col-form-label">
                        Gender
                      </label>
                      <select
                        className="form-select"
                        id="dropGender"
                        onChange={(e) =>
                          setCustomer({ ...customer, Gender: e.target.value })
                        }
                      >
                        <option label="Male" value="Male"></option>
                        <option label="Female" value="Female"></option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3 d-none">
                    <div className="mb-2">
                      <label htmlFor="txtDOB" className="col-form-label">
                        DOB
                      </label>
                      <input
                        type="Date"
                        id="txtDOB"
                        className="form-control"
                        placeholder="Enter DOB"
                        onChange={(e) => {
                          setCustomer({ ...customer, DOB: e.target.value })
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-2">
                      <label htmlFor="txtLname" className="col-form-label">
                        Certificate Type<span style={{ color: "red" }}>*</span>
                      </label>
                      <Select type="text" name="Bank" id="Bank" className="selecting"
                        options={certificateOption} required
                        styles={{
                          option: (provided, state) => ({
                            ...provided,
                            color: state.isSelected ? 'white' : 'black', // Change text color of selected option
                            backgroundColor: state.isFocused ? 'blue' : 'white', // Change background color when hovering an option
                            '&:hover': {
                              backgroundColor: 'blue', // Change background color of other options when hovered
                              color: 'white', // Change text color of other options when hovered
                            },
                          }),
                        }}
                        onChange={(e) =>
                          setCustomer({
                            ...customer,
                            Certificate_Id: e.value,
                            Certificate_Name: e.label,
                          })
                        }
                      />
                      <p className="text-danger">{errors.Certificate_Name}</p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-2">
                      <label htmlFor="Reference" className="col-form-label">
                        Reference
                      </label>
                      <input
                        type="text"
                        id="Reference"
                        className="form-control"
                        placeholder="Enter Reference"
                        onChange={(e) =>
                          setCustomer({ ...customer, Reference: e.target.value })
                        }
                      />
                      <p className="text-danger">{errors.Reference}</p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-2">
                      <label htmlFor="txtAddress" className="col-form-label">
                        Location<span style={{ color: "red" }}>*</span>
                      </label>
                      <textarea
                        type="Date"
                        id="txtAddress"
                        className="form-control"
                        placeholder="Enter Location"
                        required
                        onChange={(e) =>
                          setCustomer({
                            ...customer,
                            Address: e.target.value,
                          })
                        }
                      />
                    </div>
                    <p className="text-danger">{errors.Address}</p>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-2">
                      <label htmlFor="Remarks" className="col-form-label">
                        Remarks
                      </label>
                      <textarea
                        type="Date"
                        id="Remarks"
                        className="form-control"
                        placeholder="Enter Remarks"
                        onChange={(e) =>
                          setCustomer({
                            ...customer,
                            Remarks: e.target.value,
                          })
                        }
                      />
                    </div>
                    <p className="text-danger">{errors.Remarks}</p>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-md-12" style={{ textAlign: "center" }}>
                    <button type="submit" className="btn btn-primary " style={{ height: "38px" }}>
                      Submit
                    </button>
                  </div>
                </div>
              </form>

              <div id="form-message-warning mt-4"></div>
              <div id="form-message-success">
                Your message was sent, thank you!
              </div>
            </div>
          </div>
        </div>
        {loader}
      </div>
    </div>
  );
};
export default CustomerEnquiry;

import * as React from "react";
import { useState, useEffect } from "react";
import axios from "../../components/axios";
import { apiUrl } from "../../components/axios";
import { useUser } from "../../components/UserContext";
import "jspdf-autotable";
import useFullPageLoader from "../../components/useFullPageLoader";
import UploadPayment from "./UploadPayment"
import UploadDocument from "./UploadDocument"


const EnquiryCustomer = () => {
  const { userData } = useUser();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [data, setStaffData] = useState([]);
  const [isModal1, setIsModal1] = useState(false);
  const [isModal2, setIsModal2] = useState(false);
  const [indexValue, setIndex] = useState();

  const showModal = (index) => {
    setIndex(index);
    setIsModal1(true);
  };
  const closed = () => {
    setIsModal1(false);
    loadStaffData();
  };

  const showModal2 = (index) => {
    setIndex(index);
    setIsModal2(true);
  };
  const closed2 = () => {
    setIsModal2(false);
    loadStaffData();
  };


  useEffect(() => {
    if (userData?.UserId) {
      loadStaffData();
    }
  }, [userData]);

  const loadStaffData = async () => {
    showLoader();
    try {
      const result = await axios.get(`/getEnquiries/${userData?.UserId}`);
      //console.log(result.data);
      setStaffData(result.data);
      hideLoader();
    } catch (error) {
      hideLoader();
      console.error("Error fetching data: ", error);
    }
  };


  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
            <h4 className="page-title">View All Your Enquiries</h4>
            {/* <h5>
                <span style={{color: 'Red'}}>Note:</span> Admission forms with successful registration
                and processing fee payment only will be displayed here.<br/>
                Deposit &amp; Admission Fee Payment button will get enabled only after the admission
                is approved by the school.
                </h5> */}
          </div>
          <div className="card">
            <div className="card-body">
            {data?.map((item, index) => (
                <div key={index} className="col-md-12 mb-3" style={{ padding: '4px', border: "1px solid rgb(138 148 173 / 76%)", borderRadius: '5px' }}>
                  <h5
                    className="mb-3 text-uppercase p-2"
                    style={{
                      backgroundColor: "rgb(242, 242, 247)",
                      fontSize: "15px",
                      fontWeight: 600,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span className=''>Certificate Name : {item.Certificate_Name}</span>
                    <span
                      className={`badge ${item.Status === "Active"
                          ? "bg-label-success"
                          : item.Status === "Pending"
                            ? "bg-label-warning"
                            : "bg-label-primary"
                        } me-1`}
                    >
                      {item.Status}
                    </span>
                    {item?.Upload_Certificate && (
                      <span> View/Download :{"  "}
                      {item.Upload_Certificate ? (
                        <>
                          <a
                            style={{ color: "blue", cursor: "pointer" }}
                            href={`${apiUrl}/viewCertificate/` + item.Upload_Certificate}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="cursor-pointer"
                          >
                            <i class="ti ti-eye"></i>
                          </a>
                          /
                          <a
                            style={{ color: "blue", cursor: "pointer" }}
                            href={`${apiUrl}/downloadCetificate/` + item.Upload_Certificate}
                            download={true}
                            target="_blank"
                            className="cursor-pointer"
                          >
                            <i className="ti ti-download"></i>
                          </a>
                        </>
                      ) : (
                        <>
                          <i className="ti ti-eye-off"></i> / <i className="ti ti-download-off"></i>
                        </>
                      )}
                    </span>
                    )}
                  


                  </h5>
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <button className="btn btn-primary mx-2" disabled={item.Payment_Status === 'Inprocess' ||  item.Payment_Status === 'Approved'} onClick={() => showModal(item)}>{item.Payment_Status === 'Reject' ? 'Reupload Payment':"Upload Payment"}</button>
                      <button className="btn btn-primary mx-2" disabled={item.Document_Status === 'Inprocess' || item.Document_Status === 'Approved'} onClick={() => showModal2(item)}>{item.Document_Status === 'Reject' ? 'Reupload Document':"Upload Document"} </button>
                    </div>
                  </div>


                </div>
              ))}
            </div>
          </div>
        </div>
        {isModal1 && (
          <UploadPayment
            open={isModal1}
            onClose={closed}
            updateData={indexValue}
          />
        )}
        {isModal2 && (
          <UploadDocument
            open={isModal2}
            onClose={closed2}
            updateData={indexValue}
          />
        )}
        {loader}
      </div>
    </div>
  );
};
export default EnquiryCustomer;

import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "../../components/axios";
import useFullPageLoader from "../../components/useFullPageLoader";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import { useUser } from "../../components/UserContext";
import JSZip from 'jszip';
import FileSaver from 'file-saver';



const UploadCertificate = ({ open, onClose, updateData }) => {
    const { userData } = useUser();
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [show, setShow] = useState(open);
    const [errors, seterrors] = useState({});
    const navigate = useNavigate();
    const [documentName, setDocument] = useState([]);
    const [data, SetData] = useState();
    


    console.log(updateData)

    const [customer, setCustomer] = useState({
        Documents: [],
        Customer_Id:updateData.updateData,
        Doc_Type:updateData.Certificate_Name
    });

    const handleClose = () => {
        setShow(false);
        onClose();
    };


    useEffect(() => {
        (async () => {
            showLoader();
            try {
                if(updateData?.Sr_No){
                    const response = await axios.get(`/getCustomerDocuments/${updateData?.Sr_No}`);

                    setCustomer((customer) => ({
                        ...customer,
                        Documents: response.data,
                    }));
                   // console.log(response.data);

                }
                if (updateData?.Certificate_Id) {
                    const result = await axios.get(`/getCertificateDocByCerIdForCustomer?CerId=${updateData.Certificate_Id}`);
                   // console.log(result.data);
                    setDocument(result.data);
                }
                if (updateData?.Customer_Id) {
                    const result = await axios.get(`/getCustomerById/${updateData.Customer_Id}`);
                   // console.log(result.data);
                    SetData(result.data);
                }
            } catch (error) {
                console.error("Error fetching:", error);
            }

            hideLoader();
        })();
    }, [userData, updateData, updateData.Certificate_Id]);


    const UploadCertificateHandle = async (event) => {
        event.preventDefault();

        // Construct the data object with necessary information
        const data = {
            Org_Id: userData.Org_Id,
        
        };

        // Construct FormData object and append data
        const formdata = new FormData();
        formdata.append("Documents", JSON.stringify(customer.Documents));
        customer.Documents.forEach(document => {
            for (const [documentType, documentData] of Object.entries(document)) {
                console.log(documentType, documentData)
                formdata.append('Document_Name', documentData);
            }
          });
        formdata.append("Doc_Type", customer.Doc_Type);
        formdata.append("Customer_Id", customer.Customer_Id);

        showLoader();

        // Send PUT request
        axios
            .put(`/updateCustomerDocument/${updateData.Sr_No}`, formdata)
            .then((res) => {
                handleClose();
                Swal.fire("Success!", "Documents Uploaded Successfully.", "success");
                hideLoader();
            })
            .catch((err) => {
                console.log(err);
                Swal.fire({
                    title: "Error",
                    text: "Failed to Upload. Please try again later.",
                    icon: "error",
                    confirmButtonText: "OK",
                });
                hideLoader();
            });
    };

    const handleDocumentChange = (index, field, file) => {

        const newDocuments = Array.isArray(customer.Documents) ? [...customer.Documents] : [];
        if (!newDocuments[index]) {
            newDocuments[index] = {};
        }

        newDocuments[index][field] = file;
        setCustomer({
            ...customer,
            Documents: newDocuments
        });
    };

    const handleDownloadAllDocuments = async () => {
        if (!customer.Documents || customer.Documents.length === 0) {
            alert('No Documents Found!!');
            return;
        }
    
        const zip = new JSZip();
        const promises = [];
    
        customer.Documents.forEach(document => {
            const fileName = document.Document_Name;
            const fileUrl = `/downloadDoc/${fileName}`;
            
            // Push each axios.get request promise to an array
            promises.push(
                axios.get(fileUrl, { responseType: 'arraybuffer' })
                    .then(response => {
                        zip.file(fileName, response.data, { binary: true });
                    })
                    .catch(error => {
                        console.error('Error fetching document:', error);
                    })
            );
        });
    
        // Wait for all requests to complete
        Promise.all(promises)
            .then(() => {
                // Generate the zip file after all requests are done
                zip.generateAsync({ type: 'blob' })
                    .then(blob => {
                        FileSaver.saveAs(blob, `${data.First_Name} ${data.Last_Name} - ${updateData.Certificate_Name}.zip`);
                    })
                    .catch(error => {
                        console.error('Error generating zip file:', error);
                    });
            });
    };
    

    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="xl"
            centered
            style={{ boxShadow: "none !important" }}
        >

            <Modal.Body>
            <div className="row align-items-center">
    <h5 className="col-md-10">Upload Document - {updateData.Certificate_Name}</h5>
    <div className="col-md-2 text-end">
        <div className="mb-3">
            {customer.Documents.length > 0 ? (
                <>
                    <a style={{ color: "blue", cursor: 'pointer' }} onClick={handleDownloadAllDocuments} className="cursor-pointer">
                        <i className="ti ti-download"></i>
                    </a>
                </>
            ) : (
                <>
                    <i className="ti ti-download-off"></i>
                </>
            )}
        </div>
    </div>
</div>

              
                <hr className="p-0 mb-1" />
                <form onSubmit={UploadCertificateHandle} >
                    <div className="row justify-content-center">
                        {documentName?.map((row, index) => (
                            <>
                                <div className="col-md-4" key={index}>
                                    <div className="mb-3">
                                        <label htmlFor={`${row.Doc_Name}_${index}`} className="form-label">
                                            {row.Doc_Name}
                                        </label>
                                        <input
                                            type="file"
                                            id={`${row.Doc_Name}_${index}`}
                                            className="form-control"
                                            accept=".jpg, .jpeg, .png, .pdf"
                                            onChange={(e) => handleDocumentChange(index, `${row.Doc_Name}`, e.target.files[0])}
                                        />
                                    </div>
                                </div>
                            </>
                        ))}
                    
                    </div>

                    <div className="row justify-content-center mt-4">
                        <div className="col-md-2 col-sm-3 col-xs-3 col-3">
                            <button type="submit" className="btn btn-md btn-primary ">
                                Upload
                            </button>
                        </div>
                    </div>
                </form>

                {loader}
            </Modal.Body>
        </Modal>
    );
}
export default UploadCertificate;

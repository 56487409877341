import React from "react";

const Error = () => {
  return (
    <div class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="page-title-box justify-content-between d-flex align-items-md-center flex-md-row flex-column">
              <h4 class="page-title">404 Error</h4>
             
            </div>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-lg-4">
            <div class="text-center">
              <h1 class="text-error mt-4">404</h1>
              <h4 class="text-uppercase text-danger mt-3">Page Not Found</h4>
              <p class="text-muted mt-3">
                We,re sorry, the page you are requested could not be found.
              </p>

              {/* <a class="btn btn-info mt-3" href="/">
                <i class="ri-home-4-line me-1"></i> Back to Home
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error;

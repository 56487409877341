import * as React from "react";
import * as XLSX from "xlsx";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "../../components/axios";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from "@mui/icons-material/Delete";
import Stack from "@mui/material/Stack";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";
import { useUser } from "../../components/UserContext";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import jsPDF from "jspdf";
import Tooltip from "@mui/material/Tooltip";
import "jspdf-autotable";
import Icon from "@mdi/react";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import { mdiFileExcel } from "@mdi/js";
import useFullPageLoader from "../../components/useFullPageLoader";
import DataTable from "react-data-table-component";
import UploadCertificate from '../Cetificate/UploadCertificate'
import { formatDate } from "../../components/dateFormate";

const ViewUser = () => {
  const { userData } = useUser();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [data, setStaffData] = useState([]);
  const [dataa, setStaffDataa] = useState([]);
  const [isModal, setIsModal] = useState(false);
  const [Modaldata, setModalData] = useState();

  const closed = () => {
    setIsModal(false);
    loadStaffData();
  };

  const open = (e) => {
    setModalData(e);
    setIsModal(true);
  };

  useEffect(() => {
    loadStaffData();
  }, []);

  const loadStaffData = async () => {
    showLoader();
    try {
      const result = await axios.get(`/getCustomer`);
      console.log(result.data);
      setStaffData(result.data || []);
      setStaffDataa(result.data || []);
      hideLoader();
    } catch (error) {
      hideLoader();
      console.error("Error fetching data: ", error);
    }
  };

  const deleteUser = (UserId, Name) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        handleDelete(UserId, Name);
      }
    });
  };

  const handleDelete = async (UserId, Name) => {
    const obj = {
      Org_Id: userData.Org_Id,
      User_Role: userData.Role,
      UserName: userData.Username,
      Added_By_Id: userData.UserId,
      Added_By: `${userData.First_Name} ${userData.Last_Name}`,
      Name: Name,
    };
    try {
      await axios.delete(`/deleteCustomer/${UserId}`, { data: obj });

      Swal.fire("Deleted!", "Customer has been deleted.", "success");
      loadStaffData();
    } catch (error) {
      console.error(error);
      Swal.fire(
        "Error!",
        "An error occurred while deleting the user.",
        "error"
      );
    }
  };

  const handleSubmit = (event, Id, Name) => {
    event.preventDefault();

    const UpdatedUserDetail = {
      Org_Id: userData.Org_Id,
      User_Role: userData.Role,
      UserName: userData.Username,
      Added_By_Id: userData.UserId,
      Added_By: `${userData.First_Name} ${userData.Last_Name}`,
      Name:Name
    };
   
console.log(Id, Name);

      showLoader();
      axios
        .put(`/MarkActive/${Id}`, UpdatedUserDetail)
        .then((res) => {
          Swal.fire("Success!", "User Updated Successfully!!", "success").then(
            (result) => {
              if (result.isConfirmed) {
                loadStaffData();
              }
            }
          );
          hideLoader();;
        })
        .catch((err) => {
          console.error(err);
          hideLoader();
        });
   
  };

  const columns = [
    {
      name: "Date",
      selector: (row) => formatDate(row.Added_On),
      sortable: true,
    },
    {
      name: "ID",
      selector: (row) => row.CustomerId,
      sortable: true,
    },
    {
      name: "Name",
      cell: (row) => <Link to={`/customerProfile/${row.Sr_No}/${row.Enquiry_Sr_No}`}>{`${row.First_Name} ${row.Last_Name}`}</Link>,

      sortable: true,
    },
    {
      name: "Company Name",
      cell: (row) => (
        <LightTooltip title={row.Company_Name}>
          <span
            style={{
              maxWidth: "90px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              padding: "5px",
              cursor: "pointer",
            }}
          >
            {row.Company_Name ? row.Company_Name : "--"}
          </span>
        </LightTooltip>
      ),
    },
    {
      name: "Mobile",
      selector: (row) => row.Mobile,
    },
    {
      name: "Certificate",
      cell: (row) => (
        <LightTooltip title={row.Certificate_Name}>
          <span
            style={{
              maxWidth: "70px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              padding: "5px",
              cursor: "pointer",
            }}
          >
            {row.Certificate_Name ? row.Certificate_Name : "--"}
          </span>
        </LightTooltip>
      ),
    },
    {
      name: "Status",
      cell: (row) => (
        <span className={`badge ${row.Status === 'Active' ? 'bg-label-success' : row.Status === 'Pending' ? 'bg-label-warning' : 'bg-label-primary'} me-1`}>{row.Status}</span>
      ),
    },
    // {
    //   name: "DOB",
    //   selector: (row) => (row.DOB ? formatDate(row.DOB) : "--"),
    // },
    // {
    //   name: "Email",
    //   cell: (row) => (
    //     <LightTooltip title={row.Email}>
    //       <span
    //         style={{
    //           //maxWidth: "70px",
    //           whiteSpace: "nowrap",
    //           overflow: "hidden",
    //           textOverflow: "ellipsis",
    //           padding: "5px",
    //           cursor: "pointer",
    //         }}
    //       >
    //         {row.Email ? row.Email : "--"}
    //       </span>
    //     </LightTooltip>
    //   ),
    // },
    // {
    //   name: "Address",
    //   cell: (row) => (
    //     <LightTooltip title={row.Address}>
    //       <span
    //         style={{
    //           maxWidth: "70px",
    //           whiteSpace: "nowrap",
    //           overflow: "hidden",
    //           textOverflow: "ellipsis",
    //           padding: "5px",
    //           cursor: "pointer",
    //         }}
    //       >
    //         {row.Address ? row.Address : "--"}
    //       </span>
    //     </LightTooltip>
    //   ),
    // },
    {
      name: "",
      cell: (row) => (
        <div className="dropdown">
          <a
          className="p-0"
            type="button"
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i>
              <MoreVertIcon
                style={{
                  color: "grey",
                  fontSize: "18px",
                  cursor: "pointer",
                }}
              />
            </i>
          </a>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
          {row.Status === 'Active' && row.Upload_Certificate === null && (
              <Link className="dropdown-item" onClick={()=> open(row)}>
              <i className="ti ti-upload text-info" style={{ fontSize: "18px" }}></i>
              Upload
            </Link>)}
            {/* {row.Status !== 'Active' && (
              <Link className="dropdown-item" onClick={(e)=>handleSubmit(e, row.Enquiry_Sr_No, `${row.First_Name} ${row.Last_Name}`)}>
              <CheckIcon className="text-success" style={{ fontSize: "18px" }} />
              Make Active
            </Link>)} */}
            <Link
              className="dropdown-item"
              onClick={() =>
                deleteUser(row.Enquiry_Sr_No, `${row.First_Name} ${row.Last_Name}`)
              }
            >
              <DeleteIcon
                className="text-danger"
                style={{ fontSize: "18px" }}
              />
              Delete
            </Link>
          </div>
        </div>
      ),
      width: "45px",
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "30px",
      },
    },
  };

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  const DownloadPDF = () => {
    const doc = new jsPDF({
      orientation: "landscape",
    });
    doc.text("Customer Details", 20, 10);

    const columns = ["Date", "Customer Id", "Name", "Company Name", "Mobile", "Certificate", "DOB", "Email", "Address"];

    const rows = data.map((row) => [
      formatDate(row.Added_On),
      row.CustomerId,
      `${row.First_Name} ${row.Last_Name}`,
      row.Company_Name,
      row.Mobile,
      row.Certificate_Name,
      row.DOB ? formatDate(row.DOB) : "--",
      row.Email ? row.Email : "--",
      row.Address ? row.Address : "--",
    ]);

    doc.autoTable({
      head: [columns],
      body: rows,
    });

    doc.save("Customer Details.pdf");
  };

  const DownloadExcel = () => {
    const columns = ["Date", "Customer Id", "Name", "Company Name", "Mobile", "Certificate", "DOB", "Email", "Address"];
    const rows = [columns];
    data.forEach((row) => {
      rows.push([
        formatDate(row.Added_On),
        row.CustomerId,
        `${row.First_Name} ${row.Last_Name}`,
        row.Company_Name,
        row.Mobile,
        row.Certificate_Name,
        row.DOB ? formatDate(row.DOB) : "--",
        row.Email ? row.Email : "--",
        row.Address ? row.Address : "--",
      ]);
    });

    const workSheet = XLSX.utils.aoa_to_sheet(rows);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Customer Details");

    // Buffer
    //let buffer = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });

    // Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });

    // Download
    XLSX.writeFile(workBook, "Customer Details.xlsx");
  };

  function handleFilter(event) {
    const filterValue = event.target.value.toLowerCase();
    const filteredData = dataa.filter((row) => {
      return (
        (`${row.First_Name} ${row.Last_Name}` &&
          `${row.First_Name} ${row.Last_Name}`
            .toLowerCase()
            .includes(filterValue)) ||
        (row.Mobile && row.Mobile.toLowerCase().includes(filterValue)) ||
        (row.Email && row.Email.toLowerCase().includes(filterValue)) ||
        (row.Address && row.Address.toLowerCase().includes(filterValue))
        ||
        (row.CustomerId && row.CustomerId.toLowerCase().includes(filterValue))
        ||
        (row.Added_On && formatDate(row.Added_On).toLowerCase().includes(filterValue))
        ||
        (row.Certificate_Name && row.Certificate_Name.toLowerCase().includes(filterValue))
        ||
        (row.Company_Name && row.Company_Name.toLowerCase().includes(filterValue))
      );
    });
    setStaffData(filteredData);
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
            <h4 className="page-title">Enquiries</h4>
            {/* <div className="page-title">
              <Link to={"/addUser"}>
                <button className="btn btn-primary btn-sm text-center">
                  Add
                </button>
              </Link>
            </div> */}
          </div>
          <div className="card">
            <div className="card-body">
              <Stack
                direction="row"
                spacing={2}
                className="mx-2 my-2 mb-2"
                sx={{ zIndex: 0, position: "relative" }}
              >
                <TextField
                  onChange={handleFilter}
                  size="small"
                  label="Search..."
                />
                <div>
                  <Tooltip title="Export to PDF">
                    <i
                      style={{
                        cursor: "pointer",
                        padding: "5px",
                      }}
                      onClick={() => {
                        DownloadPDF();
                      }}
                    >
                      <PictureAsPdfIcon />
                    </i>
                  </Tooltip>

                  <Tooltip title="Export to Excel">
                    <i
                      style={{
                        padding: "5px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        DownloadExcel();
                      }}
                    >
                      {" "}
                      <Icon path={mdiFileExcel} size={1} />
                    </i>
                  </Tooltip>
                </div>
              </Stack>
              <div style={{ zIndex: 0, position: "relative" }}>
                <DataTable
                  // title="Employee Data"
                  columns={columns}
                  data={data}
                  pagination
                  highlightOnHover
                  customStyles={customStyles}
                  fixedHeader
                />
              </div>
            </div>
          </div>
        </div>

        {
          isModal && (
            <>
              <UploadCertificate
                open={isModal}
                onClose={closed}
                Modaldata= {Modaldata}
             
              />
            </>
          )
        }

        {loader}
      </div>
    </div>
  );
};
export default ViewUser;

import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "../../components/axios";
import useFullPageLoader from "../../components/useFullPageLoader";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import { useUser } from "../../components/UserContext";




const UploadCertificate = ({ open, onClose, Modaldata }) => {
  const { userData } = useUser();
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [show, setShow] = useState(open);
  const [errors, seterrors] = useState({});
  const navigate = useNavigate();

  console.log(Modaldata)

  const [upload, setUpload] = useState({
    Upload_Certificate: ""
  });

  const handleClose = () => {
    setShow(false);
    onClose();
  };



  const UploadCertificateHandle = async (event) => {
    event.preventDefault();
  
    // Construct the data object with necessary information
    const data = {
      Org_Id: userData.Org_Id,
      User_Role: userData.Role,
      UserName: userData.Username,
      Added_By_Id: userData.UserId,
      Added_By: `${userData.First_Name} ${userData.Last_Name}`,
      Enquiry_Sr_No: Modaldata?.Enquiry_Sr_No 
    };
  
    // Construct FormData object and append data
    const formdata = new FormData();
    formdata.append("Upload_Certificate", upload.Upload_Certificate);
    formdata.append("Org_Id", data.Org_Id);
    formdata.append("User_Role", data.User_Role);
    formdata.append("UserName", data.UserName);
    formdata.append("Added_By_Id", data.Added_By_Id);
    formdata.append("Added_By", data.Added_By);
  
    // Show loader while uploading
    showLoader();
  
    // Send PUT request
    axios
      .put(`/updateCustomerCertificate/${data.Enquiry_Sr_No}`, formdata)
      .then((res) => {
        handleClose();
        Swal.fire("Success!", "Certificate Uploaded Successfully.", "success");
        hideLoader();
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          title: "Error",
          text: "Failed to Upload. Please try again later.",
          icon: "error",
          confirmButtonText: "OK",
        });
        hideLoader();
      });
  };
  

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="md"
      centered
      style={{ boxShadow: "none !important" }}
    >

      <Modal.Body>
        <h5>{`${Modaldata.First_Name} ${Modaldata.Last_Name} - ${Modaldata.Certificate_Name}`}</h5>
        <hr className="p-0 mb-1" />
        <form onSubmit={UploadCertificateHandle} >
          <div className="row justify-content-center">
            <div className="col-md-12">
              <label htmlFor="Upload_Certificate">Upload Certificate</label>
              <input
                type="file"
                id="Upload_Certificate"
                name="Upload_Certificate"
                accept=".jpg, .jpeg, .png, .pdf"
                capture="false"
                className="form-control"
                onChange={(e) => {
                  setUpload({
                    ...upload,
                    Upload_Certificate: e.target.files[0]
                  })
                }}
              />
            </div>
          </div>

          <div className="row justify-content-center mt-4">
            <div className="col-md-2 col-sm-3 col-xs-3 col-3">
              <button type="submit" className="btn btn-md btn-primary ">
                Upload
              </button>
            </div>
          </div>
        </form>

        {loader}
      </Modal.Body>
    </Modal>
  );
}
export default UploadCertificate;

import * as React from "react";
import * as XLSX from "xlsx";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "../../components/axios";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";
import { useUser } from "../../components/UserContext";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import jsPDF from "jspdf";
import Tooltip from "@mui/material/Tooltip";
import "jspdf-autotable";
import Icon from "@mdi/react";
import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import { mdiFileExcel } from "@mdi/js";
import DataTable from "react-data-table-component";
import useFullPageLoader from "../../components/useFullPageLoader";
import Select from "react-select";


const BindCertificateDocument = () => {
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [Sr_No, setSrNo] = useState(null);
  const [Doc, setDocOption] = useState([]);
  const [Cer, setCerOption] = useState([]);
  const [data, setData] = useState([]);
  const [records, setRecords] = useState([]);
  const [editingItem, setEditingItem] = useState(null);
  const [error, setError] = useState("");
  const { userData } = useUser();
  const [Cer_Id, setCerId] = useState(null);
  const [Cer_Name, setCerName] = useState("");
  const [IsCertifiate, setIsCertificate] = useState([]);
  const [Documents, setDocuments] = useState(null);

  //console.log(Documents)



  const customStyles = {
    rows: {
      style: {
        minHeight: "30px",
      },
    },
  };

  useEffect(() => {
    if (userData && userData.Org_Id) {
      Load();
    }
  }, [userData, Cer_Id]);

  const Load = async () => {
    showLoader();

    const resCert = await axios.get(`/getCertificateType`);
    const CerOption = resCert.data.map((option) => ({
      label: option.Certificate_Name,
      value: option.Sr_No,
    }));
    setCerOption(CerOption);

    const resDoc = await axios.get(`/getDocumentType?OrgId=${userData.Org_Id}`);
    const DocOption = resDoc.data.map((option) => ({
      label: option.Document_Name,
      value: option.Sr_No,
    }));

    setDocOption(DocOption);

    if (editingItem && Cer_Id) {
      const resCerDoc = await axios.get(`/getCertificateDocByCerId?OrgId=${userData.Org_Id}&CerId=${Cer_Id}`);
      console.log(resCerDoc);
    }


    const result = await axios.get(
      `/getCertificateDoc?OrgId=${userData.Org_Id}`
    );
    const sortedData = result.data;
    setData(sortedData);
    setRecords(sortedData);

    if (Cer_Id) {
      const Check = await axios.get(`/getCertificateDocumnet?OrgId=${userData.Org_Id}&CerId=${Cer_Id}`);
      setIsCertificate(Check.data);
    }
    
    hideLoader();

  };

  async function save(event) {
    event.preventDefault();
    debugger;
    const error = {};
if(IsCertifiate?.length === 0){


    if (Object.keys(error).length === 0) {
      try {
        showLoader();
        await axios.post(`/bindCertificateDoc`, {
          Cer_Id: Cer_Id,
          Cer_Name: Cer_Name,
          Documents: JSON.stringify(Documents),
          Org_Id: userData.Org_Id,
          User_Role: userData.Role,
          UserName: userData.Username,
          Added_By_Id: userData.UserId,
          Added_By: `${userData.First_Name} ${userData.Last_Name}`,
        });
        Swal.fire("Success!", "Certificate With Document Has Been Mapped.", "success");
        setError("");
        setCerId(null);
        setCerName("");
        setDocuments([]);
        setSrNo(null);
        Load();
        hideLoader();
      } catch (err) {
        Swal.fire({
          title: "Error",
          text: "Failed to Map Certificate With Document. Please try again later.",
          icon: "error",
          confirmButtonText: "OK",
        });
        hideLoader();
      }
    } else {
      setError(error);
    }}else{
      Swal.fire({
        title: "Warning",
        text: "For This Certificate Documents Are Already Added.",
        icon: "warning",
        confirmButtonText: "OK"
      });      
    }
  }


  async function editExpenses(data) {
    setSrNo(data.Sr_No);
    setCerId(data.Cer_Id);
    setCerName(data.Cer_Name);
    const mappedDocuments = data.Documents.map(doc => ({
      value: doc.Doc_Id,
      label: doc.Doc_Name
    }));
    setDocuments(data.Documents ? mappedDocuments : null);
    setEditingItem(data);
  }

  async function update(event) {
    event.preventDefault();

    try {


      const updatedData = {
        Cer_Id: Cer_Id,
        Cer_Name: Cer_Name,
        Documents: JSON.stringify(Documents),
        Org_Id: userData.Org_Id,
        User_Role: userData.Role,
        UserName: userData.Username,
        Added_By_Id: userData.UserId,
        Added_By: `${userData.First_Name} ${userData.Last_Name}`,

      };
      showLoader();
      await axios.put(`/updateCertificateDoc/` + Sr_No, updatedData);
      setData((prevData) =>
        prevData.map((data) =>
          data.Sr_No === Sr_No ? { ...data, ...updatedData } : data
        )
      );
      setError("");
      setCerId(null);
      setCerName("");
      setDocuments(null);
      setSrNo(null);
      setEditingItem(null);
      Load();
      hideLoader();
      Swal.fire("Success!", "Certificate-Document Updated Successfully.", "success");
    } catch (err) {
      Swal.fire({
        title: "Error",
        text: "Failed To Certificate-Document. Please try again later.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  }

  const deleteUser = (Sr_No, name) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        handleDelete(Sr_No, name);
      }
    });
  };

  async function handleDelete(Sr_No, name) {
    const obj = {
      Org_Id: userData.Org_Id,
      User_Role: userData.Role,
      UserName: userData.Username,
      Added_By_Id: userData.UserId,
      Added_By: `${userData.First_Name} ${userData.Last_Name}`,
      Name: name
    }
    await axios.delete(`/deleteCertificateDoc/${Sr_No}`, { data: obj });
    Swal.fire("Deleted!", "Your record has been Deleted.", "success");
    Load();
  }

  const DownloadPDF = () => {
    const doc = new jsPDF();
    doc.text("Certificate-Document", 20, 10);

    const columns = [
      "Certificate",
      "Document",
    ];

    const rows = records.map((row) => [
      row.Cer_Name,
      row.Documents.map(doc => doc.Doc_Name).join(', '),
    ]);

    doc.autoTable({
      head: [columns],
      body: rows,
    });

    doc.save("Certificate-Document.pdf");
  };

  const DownloadExcel = () => {
    const columns = [
      "Certificate",
      "Document",
    ];
    const rows = [columns];
    records.forEach((row) => {
      rows.push([
        row.Cer_Name,
        row.Documents.map(doc => doc.Doc_Name).join(', '),
      ]);
    });

    const workSheet = XLSX.utils.aoa_to_sheet(rows);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Certificate-Document");

    // Buffer
    //let buffer = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });

    // Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });

    // Download
    XLSX.writeFile(workBook, "Certificate-Document.xlsx");
  };



  function handleFilter(event) {
    const filterValue = event.target.value.toLowerCase();
    const filteredData = data.filter((row) => {
      return (
        (row.Cer_Name && row.Cer_Name.toLowerCase().includes(filterValue))
      );
    });
    setRecords(filteredData);
  }

  function formatDate(dateString) {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  }



  const columns = [
    {
      name: "Certificate",
      cell: (row) => row.Cer_Name,
    },
    {
      name: "Documents",
      cell: (row) => row.Documents.map(doc => doc.Doc_Name).join(', '),
      sortable: true,
    },

    {
      name: "",
      cell: (row) => (
        <div className="dropdown">
          <a
            type="button"
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i>
              <MoreVertIcon
                style={{
                  color: "grey",
                  fontSize: "18px",
                  cursor: "pointer",
                }}
              />
            </i>
          </a>
          <div
            className="dropdown-menu"
            x-placement="bottom-start"
            style={{
              position: "absolute",
              willChange: "transform",
              top: "0px",
              left: "0px",
              transform: "translate3d(0px, 38px, 0px)",
            }}
          >
            <Link
              className="dropdown-item"
              onClick={() => editExpenses(row)}
            >
              <EditIcon
                className="text-info"
                style={{ fontSize: "18px" }}
              />{" "}
              Update
            </Link>
            <Link
              className="dropdown-item"
              onClick={() => deleteUser(row.Cer_Id, row.Cer_Name)}
            >
              <DeleteIcon
                className="text-danger"
                style={{ fontSize: "18px" }}
              />
              Delete
            </Link>
          </div>
        </div>
      ),
      width: "80px",
    },
  ];

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="page-title-box justify-content-between d-flex align-items-md-right flex-md-row flex-column">
            <h5 className="page-title">Map Certificate With Document </h5>
          </div>
          <div className="card">
            <div className="card-body">
              <Box height={10} />
              <Stack>
                <form
                  className="form-horizontal"
                  onSubmit={editingItem ? update : save}
                >
                  <div className="row">

                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="Cer_Name" className="form-label">
                          Certificate
                        </label>
                        <Select
                          name="Cer_Name"
                          id="Cer_Name"
                          //placeholder="Select Bank"
                          options={Cer}
                          value={Cer_Name ? { value: Cer_Id, label: Cer_Name } : null}
                          onChange={(event) => {
                            setCerName(event.label);
                            setCerId(event.value);
                            setError("");
                          }}

                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label htmlFor="Documents" className="form-label">
                          Documents
                        </label>
                        <Select
                          type="text"
                          name="Documents"
                          id="Documents"
                          //placeholder="Select Bank"
                          isMulti
                          options={Doc}
                          value={Documents}
                          //value={Documents ? { value: Documents.value, label: Documents.label } : null}
                          onChange={(event) => {
                            setDocuments(event);
                            setError("");
                          }}

                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 text-center">
                      <div>
                        {editingItem ? (
                          <button
                            className="btn btn-primary mb-1"
                            type="submit"
                          >
                            Update
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary mb-1"
                            type="submit"
                          >
                            Add
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
              </Stack>
            </div>
          </div>
          <div className="card mt-2">
            <div className="card-body">
              <Box height={10} />

              <Stack
                direction="row"
                spacing={2}
                className="mx-2 my-2 mb-2"
                sx={{ zIndex: 0, position: "relative" }}
              >
                <TextField
                  onChange={handleFilter}
                  size="small"
                  label="Search..."
                />
                <div>
                  <Tooltip title="Export to PDF">
                    <i
                      style={{
                        cursor: "pointer",
                        padding: "5px",
                      }}
                      onClick={() => {
                        DownloadPDF();
                      }}
                    >
                      <PictureAsPdfIcon />
                    </i>
                  </Tooltip>

                  <Tooltip title="Export to Excel">
                    <i
                      style={{
                        padding: "5px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        DownloadExcel();
                      }}
                    >
                      {" "}
                      <Icon path={mdiFileExcel} size={1} />
                    </i>
                  </Tooltip>
                </div>
              </Stack>
              <Box height={14} />
              <div style={{ zIndex: 0, position: "relative" }}>
                <DataTable
                  columns={columns}
                  data={records}
                  customStyles={customStyles}
                  fixedHeader
                  pagination
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {IsPreview && (
        <ShowPlan
          open={IsPreview}
          onClose={closed}
          data={IsPreviewData}
        />
      )} */}
      {loader}
    </div>
  );
};

export default BindCertificateDocument;
